const dangerWords = [
  ' script ',
  ' onclick ',
  // ' var ',
  ' let ',
  ' const ',
  ' function ',
  ' alert ',
  ' style ',
  '.js ',
  '.ts ',
  ' fetch ',
  '=>',
  '()',
  '{',
  '}',
  ';script',
  ';onclick',
  ';var',
  ';let',
  ';const',
  ';function',
  ';alert',
  ';style',
  '.js',
  '.ts',
  ';fetch',
  '=>',
  '()',
  '{',
  '}',
];

function checkIfHtmlTextHasJs(htmlText) {
  for (const dangerWord of dangerWords) {
    if (htmlText.toLowerCase().includes(dangerWord)) {
      alert(`В вашем тексте есть слово ${dangerWord}. Если он необходим, то обратитесь разработчику.`);
      return true;
    }
  }

  return false;
}

export default checkIfHtmlTextHasJs;
